import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost } from "../../services/httpService";
import setClinicalChecks from "../../Model/ClinicalChecks/setClinicalChecks";
import {
  getPrescriptionIncomingIdsUrl,
  getPrescriptionByIncomingIdUrl,
  getMedicationUrl,
  getPrescriptionItemChecksUrl,
  getPrescriptionChecksUrl,
  postMedicationUrl,
  getAdditionalEndorsementUrl,
  setPatientClinicalChecksUrl,
  getPatientImageUrl,
  postItemToolTipUrl,
  updateItemToolTipUrl,
  addpatientInTitanUrl,
  getDrugUomFixValueUrl,
  getTitanPatientSearchUrl,
  addEmergencySuppliesUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

var curr = new Date();
curr.setDate(curr.getDate() + 3);
var date = curr.toISOString().substring(0, 10);

const initialState = {
  isChecksLoading: false,
  isNmsPrescriptionLoading: false,
  medicationId: false,
  isSkip: false,
  isMatched: false,
  isAccess: false,
  isMandatory: false,
  isTherapy: false,
  isEndorse: false,
  returnReport: false,
  isReturnDispense: false,
  isEditable: false,
  buttonClass: false,
  isPatient: false,
  severityCount: 0,
  incomingId: "",
  patientId: "",
  patientName: "",
  itemReturnDose: "",
  itemReturnQuantity: 0,
  returnQuantity: 0,
  returnDose: "",
  returnDispense: "",
  returnMarking: "",
  returnColor: "Green",
  returnStartDate: date,
  returnStopDate: date,
  returnTherapy: 0,
  returnPreparation: 0,
  returnArchive: false,
  dispenseArray: [],
  medicineWarning: [],
  incomingIds: [],
  prescriptions: [],
  clinicalChecksPharmacy: [],
  itemChecks: [],
  patientMedication: null,
  prescriptionItemCheck: [],
  prescriptionChecks: [],
  clinicalChecksNotes: [],
  therapyComponents: [],
  defaultDosageTimings: [],
  medicationTime: [],
  prescriptionCheckGroups: [],
  postMedication: [],
  postMedicationId: null,
  additionalEndorsement: [],
  notesModel: [],
  clinicalHeaderNotesModel: [],
  patientImage: [],
  checkNowModel: null,
  clinicalChecksAdded: null,
  patientTag: [],
  isParked: false,
  isCCOrderTooltipLoading: false,
  CCOrderTooltipData: null,
  toolTipSummaryData: [],
  updateCCOrderTooltipLoading: false,
  selectedBucketType: 3,
  isTherapyModal: false,
  therapyModelContents: null,
  isPatientAddedInTitan: false,
  prescribedItemInfoModel: null,
  uom: [],
  patientSearchLoader: false,
  patientSearchItems: [],
  openFindPatientModal: false,
  openFindPatientComparisonModal: false,
  emergencySupplies: [],
  isCCModalOpen: false,
  CCModalLoader: false,
};
// const validationChecks = [11, 18, 100, 9, 7, 8];
const validationChecks = [18, 100];
const therapyChanges = [44, 45, 46, 47, 48];

export const getPrescriptionIncomingIdsData = createAsyncThunk(
  "clinicalChecks/getPrescriptionIncomingIdsData",
  async (params) => {
    const { pharmacyId, currentTeamId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };
    const respData = await httpGet(
      getPrescriptionIncomingIdsUrl(pharmacyId, currentTeamId)
    );
    return respData;
  }
);

export const getPrescriptionByIncomingIdData = createAsyncThunk(
  "clinicalChecks/getPrescriptionByIncomingIdData",
  async (params) => {
    const { prescriptionIncomingId, pharmacyID } = params || {};
    const respData = await httpGet(
      getPrescriptionByIncomingIdUrl(prescriptionIncomingId, pharmacyID)
    );
    return respData;
  }
);

export const getMedicationData = createAsyncThunk(
  "clinicalChecks/getMedicationData",
  async (params) => {
    const { patientId, pharmacyID } = params || {};
    const respData = await httpGet(
      getMedicationUrl(
        patientId === null ? "00000000-0000-0000-0000-000000000000" : patientId,
        pharmacyID
      )
    );
    return respData;
  }
);
export const getAdditionalEndorsementData = createAsyncThunk(
  "clinicalChecks/getAdditionalEndorsementData",
  async (params) => {
    const { PrescribedItemId, pharmacyID } = params || {};
    const respData = await httpGet(
      getAdditionalEndorsementUrl(PrescribedItemId, pharmacyID)
    );
    return respData;
  }
);
export const getPatientImageData = createAsyncThunk(
  "clinicalChecks/getPatientImageData",
  async (params) => {
    const { imageValue, pharmacyID } = params || {};
    const respData = await httpGet(getPatientImageUrl(imageValue, pharmacyID));
    return respData;
  }
);

export const setPatientClinicalChecksData = createAsyncThunk(
  "clinicalChecks/setPatientClinicalChecksData",
  async (params) => {
    const { checkNowModel, pharmacyID } = params || {};
    const respData = await httpPost(
      setPatientClinicalChecksUrl(pharmacyID),
      checkNowModel
    );
    return respData;
  }
);
export const getPrescriptionItemChecksData = createAsyncThunk(
  "clinicalChecks/getPrescriptionItemChecksData",
  async (params) => {
    const { prescriptionItemChecksBody, pharmacyID } = params || {};
    const respData = await httpPost(
      getPrescriptionItemChecksUrl(pharmacyID),
      prescriptionItemChecksBody
    );
    return respData;
  }
);
export const getPrescriptionChecksData = createAsyncThunk(
  "clinicalChecks/getPrescriptionChecksData",
  async (params) => {
    const { prescriptionCheckBody, pharmacyID } = params || {};
    const respData = await httpPost(
      getPrescriptionChecksUrl(pharmacyID),
      prescriptionCheckBody
    );
    return respData;
  }
);
export const addEmergencySuppliesData = createAsyncThunk(
  "clinicalChecks/addEmergencySuppliesData",
  async (params) => {
    const { pharmacyID, patientID, prescribedItemID } = params || {};
    const respData = await httpGet(
      addEmergencySuppliesUrl(pharmacyID, patientID, prescribedItemID)
    );
    return respData;
  }
);
export const getPostMedicationData = createAsyncThunk(
  "clinicalChecks/getPostMedicationData",
  async (params) => {
    const { prescriptionCheckBody, pharmacyID } = params || {};
    const respData = await httpPost(
      postMedicationUrl(pharmacyID),
      prescriptionCheckBody
    );
    return respData;
  }
);

export const setCCOrderItemToolTipData = createAsyncThunk(
  "clinicalChecks/setCCOrderItemToolTipData",
  async (params) => {
    const { CCOrderTooltipBody, pharmacyID } = params || {};
    const respData = await httpPost(
      postItemToolTipUrl(pharmacyID),
      CCOrderTooltipBody
    );
    return respData;
  }
);

export const updateCCOrderItemToolTipData = createAsyncThunk(
  "clinicalChecks/updateCCOrderItemToolTipData",
  async (params) => {
    const { CCUpdateOrderTooltipBody, pharmacyID } = params || {};
    const respData = await httpPost(
      updateItemToolTipUrl(pharmacyID),
      CCUpdateOrderTooltipBody
    );
    return respData;
  }
);

export const addpatientInTitanData = createAsyncThunk(
  "clinicalChecks/addpatientInTitanData",
  async (params) => {
    const { patientAddBody, pharmacyID } = params || {};
    const respData = await httpPost(
      addpatientInTitanUrl(pharmacyID),
      patientAddBody
    );
    return respData;
  }
);

export const getDrugUomFixValueData = createAsyncThunk(
  "clinicalChecks/getDrugUomFixValueData",
  async (params) => {
    const { prescribedItemID, prescriptionOriginCountry, pharmacyID } =
      params || {
        pharmacyId: "00000000-0000-0000-0000-000000000000",
      };
    const respData = await httpGet(
      getDrugUomFixValueUrl(
        prescribedItemID,
        prescriptionOriginCountry,
        pharmacyID
      )
    );
    return respData;
  }
);

export const getTitanPatientSearchData = createAsyncThunk(
  "clinicalChecks/getTitanPatientSearchData",
  async (params) => {
    const { patientFindBody, pharmacyID } = params || {};
    const respData = await httpPost(
      getTitanPatientSearchUrl(pharmacyID),
      patientFindBody
    );
    return respData;
  }
);

const clinicalChecksSlice = createSlice({
  name: "clinicalChecks",
  initialState,
  reducers: {
    setIsClinicalChecksLoading: (state, action) => {
      state.isChecksLoading = action?.payload || false;
    },
    setIsNmsPrescriptionLoading: (state, action) => {
      state.isNmsPrescriptionLoading = action?.payload || false;
    },
    setIsSkip: (state, action) => {
      state.isSkip = action?.payload || false;
    },
    setIsMatched: (state, action) => {
      state.isMatched = action?.payload || false;
    },
    setIsMandatory: (state, action) => {
      state.isMandatory = action?.payload || false;
    },
    setIsTheraphy: (state, action) => {
      state.isTherapy = action?.payload || false;
    },
    setIsEndorse: (state, action) => {
      state.isEndorse = action?.payload || false;
    },
    setIsSeverityCount: (state, action) => {
      state.severityCount = action?.payload || false;
    },
    setIsCheckNowModel: (state, action) => {
      state.checkNowModel = action?.payload || false;
    },
    setClinicalChecksNotes: (state, action) => {
      state.clinicalChecksNotes = action?.payload || [];
    },
    setIsDispenseArray: (state, action) => {
      state.dispenseArray = action?.payload || false;
    },
    setIsItemchecks: (state, action) => {
      state.prescriptionItemCheck = action?.payload || false;
    },
    setIsAccess: (state, action) => {
      state.isAccess = action?.payload || false;
    },
    setIsItemReturnDose: (state, action) => {
      state.itemReturnDose = action?.payload || false;
    },
    setIsItemReturnQuantity: (state, action) => {
      state.itemReturnQuantity = action?.payload || false;
    },
    setIsReturnQuantity: (state, action) => {
      state.returnQuantity = action?.payload || false;
    },
    setIsReturnDose: (state, action) => {
      state.returnDose = action?.payload || false;
    },
    setIsReturnDispense: (state, action) => {
      state.returnDispense = action?.payload || false;
    },
    setIsReturnStartDate: (state, action) => {
      state.returnStartDate = action?.payload || false;
    },
    setIsReturnStopDate: (state, action) => {
      state.returnStopDate = action?.payload || false;
    },
    setIsReturnMarking: (state, action) => {
      state.returnMarking = action?.payload || false;
    },
    setIsReturnTherapy: (state, action) => {
      state.returnTherapy = action?.payload || false;
    },
    setIsReturnPreparation: (state, action) => {
      state.returnPreparation = action?.payload || false;
    },
    setIsReturnArchive: (state, action) => {
      state.returnArchive = action?.payload || false;
    },
    setIsReturnColor: (state, action) => {
      state.returnColor = action?.payload || false;
    },
    setIsReturnReport: (state, action) => {
      state.returnReport = action?.payload || false;
    },
    setIsDispense: (state, action) => {
      state.isReturnDispense = action?.payload || false;
    },
    setIsEditable: (state, action) => {
      state.isEditable = action?.payload || false;
    },
    setIsButtonclass: (state, action) => {
      state.buttonClass = action?.payload || false;
    },
    setIsNotesModel: (state, action) => {
      state.notesModel = action?.payload || [];
    },
    setIsClinicalHeaderNotesModel: (state, action) => {
      state.clinicalHeaderNotesModel = action?.payload || [];
    },
    setMedicationTimeModel: (state, action) => {
      state.medicationTime = action?.payload || false;
    },
    setIsMedicineWarning: (state, action) => {
      state.medicineWarning = action?.payload || false;
    },
    setIsPatientImage: (state, action) => {
      state.patientImage = action?.payload || false;
    },
    setIsMedicationId: (state, action) => {
      state.medicationId = action?.payload || false;
    },
    setIsParkedData: (state, action) => {
      state.isParked = action?.payload || false;
    },
    setSelectedBucketTypeData: (state, action) => {
      state.selectedBucketType = action?.payload || 3;
    },
    setIsTheraphyModal: (state, action) => {
      state.isTherapyModal = action?.payload || false;
    },
    setTherapyModelContent: (state, action) => {
      state.therapyModelContents = action?.payload;
    },
    setOpenFindPatientModalData: (state, action) => {
      state.openFindPatientModal = action?.payload;
    },
    setOpenFindPatientComparisonModalData: (state, action) => {
      state.openFindPatientComparisonModal = action?.payload;
    },
    setCCModalOpenData: (state, action) => {
      state.isCCModalOpen = action?.payload || false;
    },
    setCCModalLoaderData: (state, action) => {
      state.CCModalLoader = action?.payload || false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrescriptionIncomingIdsData.pending, (state) => {
        state.isChecksLoading = true;
        state.incomingIds = [];
      })
      .addCase(getPrescriptionIncomingIdsData.fulfilled, (state, action) => {
        state.isChecksLoading = false;
        state.incomingIds = action?.payload || [];
        if (action?.payload.length === 0) {
          state.isPatient = false;
        } else {
          state.isPatient = true;
        }
      })
      .addCase(getPrescriptionIncomingIdsData.rejected, (state, action) => {
        logError(action?.error);
        state.isChecksLoading = false;
        state.incomingIds = [];
      })
      .addCase(getPrescriptionByIncomingIdData.pending, (state) => {
        state.isNmsPrescriptionLoading = true;
        state.prescriptions = [];
      })
      .addCase(getPrescriptionByIncomingIdData.fulfilled, (state, action) => {
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            const { prescriptionGetModel, pharmacy } =
              appDataFromResponse || {};
            state.selectedBucketType = prescriptionGetModel?.bucketType;
            state.prescriptions = prescriptionGetModel;
            state.clinicalChecksPharmacy = pharmacy;
            const { patient } = prescriptionGetModel || {};
            state.patientTag = patient?.patientTags;
            state.patientName = `${patient?.title} ${patient?.forename} ${patient?.middleName} ${patient?.surname}`;
            const { patientId, prescriptionItems } = prescriptionGetModel || {};
            state.isParked =
              state.patientTag?.find(
                (tag) => tag.tagName === "Send to parked"
              ) || false;
            state.patientId = patientId;
            // if (patientId) {
            //   state.isSkip = false;
            // } else {
            //   state.isSkip = true;
            // }
            prescriptionItems?.map((value) => {
              if (value?.notes?.length > 0) {
                state.notesModel = value?.notes;
              }
              if (
                value.prescribedItemId ===
                  "00000000-0000-0000-0000-000000000000" ||
                value.prescribedItemId === null
              ) {
                state.isMatched = true;
              } else {
                state.isMatched = false;
              }
            });
            state.checkNowModel = new setClinicalChecks(
              prescriptionGetModel,
              0,
              "",
              "",
              false,
              "",
              ""
            );
          } catch (error) {
            logError(error);
          }
        } else {
          state.isPatient = false;
        }
      })
      .addCase(getPrescriptionByIncomingIdData.rejected, (state, action) => {
        logError(action?.error);
        state.prescriptions = [];
      })
      .addCase(getMedicationData.pending, (state) => {
        state.isChecksLoading = true;
        state.patientMedication = null;
      })
      .addCase(getMedicationData.fulfilled, (state, action) => {
        state.isChecksLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            if (appDataFromResponse.patientMedications !== null) {
              appDataFromResponse.patientMedications.sort(function (a, b) {
                return a.prescribedItem.writtenAs.localeCompare(
                  b.prescribedItem.writtenAs.name
                );
              });
              state.therapyComponents = appDataFromResponse?.therapyComponents;
              if (
                appDataFromResponse?.therapyComponents?.includes(4) ||
                appDataFromResponse?.therapyComponents?.includes(5)
              ) {
                state.isTherapy = true;
              }
              if (appDataFromResponse?.defaultDosageTimings?.length !== 0) {
                appDataFromResponse?.defaultDosageTimings?.sort(function (
                  a,
                  b
                ) {
                  return a.patientMedicationOrder - b.patientMedicationOrder;
                });

                state.defaultDosageTimings =
                  appDataFromResponse?.defaultDosageTimings;
              }
            }
          } catch (error) {
            logError(error);
          }
        }

        state.patientMedication = appDataFromResponse;
      })
      .addCase(getMedicationData.rejected, (state, action) => {
        logError(action?.error);
        state.isChecksLoading = false;
        state.patientMedication = null;
      })
      .addCase(getPrescriptionItemChecksData.pending, (state) => {
        state.isChecksLoading = true;
        state.prescriptionItemCheck = [];
      })
      .addCase(getPrescriptionItemChecksData.fulfilled, (state, action) => {
        state.isChecksLoading = false;
        state.openFindPatientModal = false;
        state.openFindPatientComparisonModal = false;
        const appDataFromResponse = action?.payload;

        const groupChecks = () => {
          try {
            const groupedPrescriptionChecks = {};
            appDataFromResponse.forEach((item) => {
              const key = `${item.clinicalCheckId}-${item.canGroup}-${item.prescribedItemId}`;
              if (!groupedPrescriptionChecks[key]) {
                groupedPrescriptionChecks[key] = [];
              }
              groupedPrescriptionChecks[key].push(item);
            });

            state.prescriptionCheckGroups = Object.values(
              groupedPrescriptionChecks
            );
          } catch (error) {
            logError(error);
          }
        };
        if (appDataFromResponse) {
          try {
            appDataFromResponse?.map((item) => {
              if (item.checkSeverity == 2) {
                state.severityCount += 1;
              } else {
                state.severityCount = 0;
              }

              // item.clinicalCheckOptions?.map((value) => {
              //   if (validationChecks.includes(value.clinicalCheckActionType)) {
              //     state.isAccess = true;
              //   } else {
              //     state.isAccess = false;
              //   }
              // });
            });
            groupChecks();
          } catch (error) {
            logError(error);
          }
        }

        state.prescriptionItemCheck = appDataFromResponse;
      })
      .addCase(getPrescriptionItemChecksData.rejected, (state, action) => {
        logError(action?.error);
        state.isChecksLoading = false;
        state.prescriptionItemCheck = [];
      })
      .addCase(getPrescriptionChecksData.pending, (state) => {
        state.isChecksLoading = true;
        state.prescriptionChecks = [];
      })
      .addCase(getPrescriptionChecksData.fulfilled, (state, action) => {
        state.isChecksLoading = false;
        state.openFindPatientModal = false;
        state.openFindPatientComparisonModal = false;
        state.prescriptionChecks = action?.payload || [];
      })
      .addCase(getPrescriptionChecksData.rejected, (state, action) => {
        logError(action?.error);
        state.isChecksLoading = false;
        state.prescriptionChecks = [];
      })
      .addCase(getPostMedicationData.pending, (state) => {
        state.isChecksLoading = true;
        state.postMedication = [];
      })
      .addCase(getPostMedicationData.fulfilled, (state, action) => {
        state.isChecksLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.postMedicationId = appDataFromResponse?.patientMedicationId;
          } catch (error) {
            logError(error);
          }
        }
        state.postMedication = appDataFromResponse;
      })
      .addCase(getPostMedicationData.rejected, (state, action) => {
        logError(action?.error);
        state.isChecksLoading = false;
        state.postMedication = [];
      })
      .addCase(getAdditionalEndorsementData.pending, (state) => {
        state.isChecksLoading = true;
        state.additionalEndorsement = [];
      })
      .addCase(getAdditionalEndorsementData.fulfilled, (state, action) => {
        state.isChecksLoading = false;
        state.additionalEndorsement = action?.payload || [];
        state.isEndorse = true;
      })
      .addCase(getAdditionalEndorsementData.rejected, (state, action) => {
        logError(action?.error);
        state.isChecksLoading = false;
        state.additionalEndorsement = [];
      })
      .addCase(setPatientClinicalChecksData.pending, (state) => {
        state.isChecksLoading = true;
        state.clinicalChecksAdded = [];
      })
      .addCase(setPatientClinicalChecksData.fulfilled, (state, action) => {
        state.isChecksLoading = false;
        state.clinicalChecksAdded = action?.payload || [];
      })
      .addCase(setPatientClinicalChecksData.rejected, (state, action) => {
        logError(action?.error);
        state.isChecksLoading = false;
        state.clinicalChecksAdded = [];
      })
      .addCase(getPatientImageData.pending, (state) => {
        state.isNmsPrescriptionLoading = true;
        state.patientImage = [];
      })
      .addCase(getPatientImageData.fulfilled, (state, action) => {
        state.isNmsPrescriptionLoading = false;
        state.patientImage = action?.payload || [];
      })
      .addCase(getPatientImageData.rejected, (state, action) => {
        logError(action?.error);
        state.isNmsPrescriptionLoading = false;
        state.patientImage = [];
      })
      .addCase(setCCOrderItemToolTipData.pending, (state) => {
        state.isCCOrderTooltipLoading = true;
        state.CCOrderTooltipData = null;
        state.toolTipSummaryData = [];
      })
      .addCase(setCCOrderItemToolTipData.fulfilled, (state, action) => {
        state.isCCOrderTooltipLoading = false;
        const appDataFromResponse = action?.payload;

        if (appDataFromResponse) {
          try {
            state.CCOrderTooltipData =
              appDataFromResponse?.stockItemTooltipSummary;
            state.toolTipSummaryData =
              appDataFromResponse?.orderTrolleyProfileModel;
          } catch (error) {
            logError(error);
          }
        }
      })
      .addCase(setCCOrderItemToolTipData.rejected, (state, action) => {
        logError(action?.error);
        state.isCCOrderTooltipLoading = false;
        state.CCOrderTooltipData = null;
        state.toolTipSummaryData = [];
      })
      .addCase(updateCCOrderItemToolTipData.pending, (state) => {
        state.updateCCOrderTooltipLoading = true;
      })
      .addCase(updateCCOrderItemToolTipData.fulfilled, (state, action) => {
        state.updateCCOrderTooltipLoading = false;
      })
      .addCase(updateCCOrderItemToolTipData.rejected, (state, action) => {
        logError(action?.error);
        state.updateCCOrderTooltipLoading = false;
      })
      .addCase(addpatientInTitanData.pending, (state) => {
        state.isPatientAddedInTitan = true;
      })
      .addCase(addpatientInTitanData.fulfilled, (state, action) => {
        state.isPatientAddedInTitan = action?.payload;
      })
      .addCase(addpatientInTitanData.rejected, (state, action) => {
        logError(action?.error);
        state.isPatientAddedInTitan = false;
      })
      .addCase(getDrugUomFixValueData.pending, (state) => {
        state.isChecksLoading = true;
        state.prescribedItemInfoModel = null;
      })
      .addCase(getDrugUomFixValueData.fulfilled, (state, action) => {
        state.isChecksLoading = false;
        const appDataFromResponse = action?.payload;

        if (appDataFromResponse) {
          try {
            state.prescribedItemInfoModel = appDataFromResponse;
            if (state?.prescribedItemInfoModel != null) {
              state.uom = appDataFromResponse?.uom;
            }
          } catch (error) {
            logError(error);
          }
        }
      })
      .addCase(getTitanPatientSearchData.pending, (state) => {
        state.patientSearchLoader = true;
      })
      .addCase(getTitanPatientSearchData.fulfilled, (state, action) => {
        state.patientSearchLoader = false;
        state.patientSearchItems = action?.payload;
      })
      .addCase(getTitanPatientSearchData.rejected, (state, action) => {
        logError(action?.error);
        state.patientSearchLoader = false;
      })
      .addCase(addEmergencySuppliesData.pending, (state) => {
        state.emergencySupplies = [];
      })
      .addCase(addEmergencySuppliesData.fulfilled, (state, action) => {
        state.emergencySupplies = action?.payload || [];
      })
      .addCase(addEmergencySuppliesData.rejected, (state, action) => {
        logError(action?.error);
        state.emergencySupplies = [];
      });
  },
});

export const {
  incomingId,
  setIsClinicalChecksLoading,
  setIsNmsPrescriptionLoading,
  setIsSkip,
  setIsMatched,
  setIsAccess,
  setIsEndorse,
  setIsCheckNowModel,
  setIsItemchecks,
  setIsSeverityCount,
  setIsMandatory,
  setIsDispenseArray,
  setClinicalChecksNotes,
  setIsTheraphy,
  setIsItemReturnDose,
  setIsItemReturnQuantity,
  setIsReturnQuantity,
  setIsReturnDose,
  setIsReturnDispense,
  setIsReturnStartDate,
  setIsReturnStopDate,
  setIsReturnMarking,
  setIsReturnTherapy,
  setIsReturnArchive,
  setIsReturnColor,
  setIsReturnPreparation,
  setIsReturnReport,
  setIsDispense,
  setIsEditable,
  setIsButtonclass,
  setIsNotesModel,
  setIsClinicalHeaderNotesModel,
  setMedicationTimeModel,
  setIsMedicineWarning,
  setIsPatientImage,
  setIsMedicationId,
  setIsParkedData,
  setSelectedBucketTypeData,
  setIsTheraphyModal,
  setTherapyModelContent,
  setOpenFindPatientModalData,
  setOpenFindPatientComparisonModalData,
  setCCModalOpenData,
  setCCModalLoaderData,
} = clinicalChecksSlice.actions;

export default clinicalChecksSlice.reducer;
